import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';

const Loader = ({ loading, children, pageLoader, optionalClass }) => {
  const spinner = (
    <div
      className={classNames('main-loader', optionalClass, {
        'main-loader-page': pageLoader
      })}
    >
      <span className="main-loader-span" />
    </div>
  );

  return <>{(loading && spinner) || children}</>;
};

Loader.propTypes = {
  loading: PropTypes.bool,
  pageLoader: PropTypes.bool,
  optionalClass: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Loader;
